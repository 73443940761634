import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { useForm } from "react-hook-form";
import { turnstileChallenge } from "~/javascripts/turnstile";

const countryCode = [
  ["BD +880", 880],
  ["BR +55", 55],
  ["CN +86", 86],
  ["HK +852", 852],
  ["ID +62", 62],
  ["JP +81", 81],
  ["MO +853", 853],
  ["MY +60", 60],
  ["NZ +64", 64],
  ["SG +65", 65],
  ["TW +886", 886],
  ["TH +66", 66],
  ["VN +84", 84]
]

const LoginForm = ({ translation: t}) => {
  const [loginErrors, setLoginErrors] = useState("")
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [isLoading, setLoadingState ] = useState(false);
  const [returnLink, setReturnLink] = useState(new URLSearchParams(window.location.search).get("return_link"))

  const loginUser = async (data) => {
    const turnstileToken = await turnstileChallenge('login')
    const csrfToken = document.querySelector("[name='csrf-token']").content

    return await fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(
        {
          turnstile_token: turnstileToken,
          ...data
        }
      )
    })
  }

  const {
    register,
    watch,
    handleSubmit,
    resetField,
    formState: {
      isValid,
      errors,
    }
  } = useForm({
    defaultValues: {
      login_type: 'email',
    },
    mode: 'onChange'
  });

  const onSubmit = loginData => {
    setLoadingState(true);
    loginUser(loginData).then((res) => {
      return res.json();
    }).then(({ success, errors }) => {
      setLoadingState(false);
      if (success) {
        setLoginSuccess(true);
        setTimeout(() => {
          window.location.assign(returnLink || ('/'));
        }, 2000);

      }
      if (errors) {
        resetField('password');
        setLoginErrors(errors?.login_error)
      }
    }).catch((error) => {
      console.log(error)
    });
  };

  const isLoginWithEmail = watch('login_type') === 'email';

  React.useEffect(() => {
    resetField('email');
    resetField('phone');
  }, [isLoginWithEmail]);

  const renderFormBg = () => {
    if (isLoading) {
      return 'loading'
    }
    if (Object.keys(errors).length > 0) {
      return 'error'
    }
    if (isValid) {
      return 'success'
    }
    return ''
  }

  const renderButtonText = () => {
    if (isLoading) {
      return t.logging_in
    }
    if (loginSuccess) {
      return t.login_success
    }
    return t.login
  }

  return (
    <div className={`
      grid gap-3 items-start transition login-form ${loginSuccess && 'success'}
    `}>
      <div className={`login-blob ${renderFormBg()}`}>
        <div className="blob"></div>
        <div className="blob"></div>
      </div>
      {
        loginSuccess && (
          <div className="success-redirecting">
              <div className="text-center">
              <p className="text-gradient yellow text-xl block font-bold mb-10 uppercase">
                { t.login_success }
              </p>
              <p className="text-gray-300 block italic">
                {
                  returnLink ? ( t.redirecting_to_return_link) : ( t.redirecting )
                }
              </p>
            </div>
          </div>
        )
      }
      <form onSubmit={handleSubmit(onSubmit)} id="loginForm"> {/* id used for GA tracking*/}
        <div className="grid grid-cols-2 relative rounded mb-4 bg-gray-900 border border-zinc-500">
          <div className="z-10 relative">
            <input
              {...register("login_type", { required: true })}
              type="radio"
              value="email"
              id="email-login-type"
              className="hidden text-indigo-600 focus:ring-indigo-600"
            />
            <label htmlFor="email-login-type" className="flex bg-black/10 hover:bg-black/20 items-center rounded cursor-pointer justify-center w-full h-11 block text-sm font-medium leading-6 text-white">
              {t.email}
            </label>
          </div>
          <div className="z-10 relative">
            <input
              {...register("login_type", { required: true })}
              type="radio"
              value="phone"
              id="phone-login-type"
              className="hidden border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label htmlFor="phone-login-type" className="flex bg-black/10 hover:bg-black/20 items-center rounded cursor-pointer justify-center w-full h-11 block text-sm font-medium leading-6 text-white">
              {t.phone}
            </label>
          </div>
          <div className={`
            absolute top-0 left-0 h-full bg-pink-700 w-1/2 z-0 rounded transform transition
            ${isLoginWithEmail ? 'translate-x-0' : 'translate-x-full'}
          `}></div>
        </div>
        {
          isLoginWithEmail ? (
            <div className="mb-4">
              <label className="block font-medium" htmlFor="email">{ t.email }</label>
              <input
                name="email"
                type="email"
                {...register("email", {
                  required: "required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid Email",
                  }
                })}
                className={
                  `
                    block h-12 w-full bg-gray-900 rounded-md p-4 mt-2 focus:ring-0
                    ${errors?.email ? "focus:border-red-700" : "focus:border-teal-900"}
                  `
                }
              />
            </div>
          ) : (
            <div className="mb-4 grid grid-cols-3 gap-2">
              <label className="block font-medium col-span-3" htmlFor="phone">{ t.phone }</label>
              <select
                {...register("country_code", { required: true })}
                className="h-12 px-2 bg-gray-900 block rounded-md"
              >
                {
                  countryCode.map((option, index) => {
                    return (
                      <option value={option[1]} key={index}>
                        {option[0]}
                      </option>
                    )
                  })
                }
              </select>
              <div className="col-span-2">
                <input
                  name="phone"
                  type="text"
                  {...register("phone", {
                    required: "required",
                    pattern: {
                      value: /[0-9]+/,
                    }
                  })}
                  className={`
                    block h-12 w-full bg-gray-900 rounded-md p-4 focus:ring-0
                    ${errors?.phone ? "focus:border-red-700" : "focus:border-teal-900"}
                  `}

                />
              </div>
            </div>
          )
        }
        <div className="mb-8">
          <label className="block font-medium" htmlFor="password">
            { t.password }
          </label>
          <input
            name="password"
            type="password"
            className={`
              block h-12 w-full bg-gray-900 rounded-md p-4 mt-2 focus:ring-0
              ${errors?.password ? "focus:border-red-400/40" : "focus:border-teal-500/40"}
            `}

            {...register("password", { required: true, maxLength: 20, minLength: 8 })}
          />
        </div>

        {
          loginErrors && <p className="italic text-red-500 text-sm text-center mb-4">{loginErrors}</p>
        }
        <button
          disabled={!isValid}
          id="login-button"
          className={`font-semibold py-4 w-full
            ${isValid ? 'bg-teal-800 text-white' : 'bg-zinc-600 text-zinc-500 hover:animate-shake'}
            rounded-md cursor-pointer group`}
          type="submit"
        >
          { renderButtonText() }
        </button>
      </form>
    </div>
  )
}

const node = document.getElementById('login-root')
const data = JSON.parse(node.getAttribute('data'))

const root = ReactDOM.createRoot(
  node,
);

root.render(<LoginForm {...data} />);
